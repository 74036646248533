import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { Button, Form as BootstrapForm, Row, Col, Breadcrumb } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import { CATEGORIES, ADD } from 'config';
import { CommonHelmet } from 'common';

const initialValues = {
  category_type: '',
};

const AddCategory = () => {
  const [selected, setSelected] = useState(localStorage.getItem('selectedCategory') || '');
  const navigate = useNavigate();

  const handleSubmit = (values, { setSubmitting }) => {
    localStorage.setItem('selectedCategory', values.category_type);
    navigate(CATEGORIES + ADD + '/' + values.category_type);
    setSubmitting(false);
  };

  return (
    <>
      <CommonHelmet title={'Category Add'} description={'Category Add'} />
      <Breadcrumb>
        <Breadcrumb.Item href="/category/list">Category List</Breadcrumb.Item>
        <Breadcrumb.Item active>Select Category</Breadcrumb.Item>
      </Breadcrumb>
      <div className="content-wrapper">
        <section id="content-wrapper">
          <div className="waste-main">
            <Row>
              <Col lg={12}>
                <div className="inner-box">
                  <div className="edit-profile-form">
                    <h1 className="page-heading-center">Select Category to Add</h1>
                    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                      {({ isSubmitting, setFieldValue }) => (
                        <Form>
                          <Row className="justify-content-center h-100 align-items-center">
                            <Col sm={6}>
                              <BootstrapForm.Group>
                                <BootstrapForm.Check
                                  type="radio"
                                  id="category_type_1"
                                  label="Parent Category"
                                  name="category_type"
                                  value="parent-category"
                                  checked={selected == 'parent-category'}
                                  onChange={(e) => {
                                    setFieldValue('category_type', e.target.value);
                                    setSelected(e.target.value);
                                  }}
                                />
                                <BootstrapForm.Check
                                  type="radio"
                                  id="category_type_2"
                                  label="Sub Category"
                                  name="category_type"
                                  value="sub-category"
                                  checked={selected === 'sub-category'}
                                  onChange={(e) => {
                                    setFieldValue('category_type', e.target.value);
                                    setSelected(e.target.value);
                                  }}
                                />
                                <BootstrapForm.Check
                                  type="radio"
                                  id="category_type_3"
                                  label="Individual Category"
                                  name="category_type"
                                  value="individual-category"
                                  checked={selected === 'individual-category'}
                                  onChange={(e) => {
                                    setFieldValue('category_type', e.target.value);
                                    setSelected(e.target.value);
                                  }}
                                />
                              </BootstrapForm.Group>
                            </Col>
                          </Row>

                          <div className="text-center mb-5 active-color">
                            <Button
                              variant="secondary"
                              onClick={() => navigate('/category/list')}
                              className="me-2">
                              Cancel
                            </Button>
                            <Button
                              type="submit"
                              variant="primary"
                              disabled={isSubmitting}
                              id="submit_btn"
                              onClick={() => {
                                if (!selected) {
                                  alert('Please select the category');
                                }
                              }}>
                              Save
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </>
  );
};

export default AddCategory;
