import React from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import 'assets/scss/page/_alert_confirm.scss';
import { CommonHelmet, TNBreadCrumb } from 'common';
import { LIST, EDIT } from 'config';
import { useViewCategory } from 'hooks';
import { CATEGORIES } from 'config';

const ViewCategory = ({ t }) => {
  const navigate = useNavigate();
  let { category_id } = useParams();
  const breadcrumbArray = [
    {
      label: t('page.category_list_label'),
      link: CATEGORIES + LIST,
      active: '',
    },
    {
      label: t('page.view_category_label'),
      link: CATEGORIES + EDIT + category_id,
      active: 'active',
    },
  ];

  const { data: viewCategoryData } = useViewCategory(
    Number(category_id),
    (data) => {
      console.log('data: ', data);
    },
    (error) => {
      if (error.data.redirect == true) {
        toast.error(error.message);
        navigate(DASHBOARD);
      }
    }
  );

  return (
    <>
      <CommonHelmet title={'Category Details'} description={'Category Details'} />
      <TNBreadCrumb breadCrumbArray={breadcrumbArray} />
      <Card className="inner-box mt-4">
        <div className="edit-profile-form">
          <h1 className="text-start">{t('page.view_category_label')}</h1>
          <div className="row mb-4 mb-sm-2">
            <div className="col-sm-3">
              <h5 className="f-w-500 d-flex justify-content-between">
                {t('page.category_parent_category')}
                <span className="pull-right d-none d-sm-block">:</span>
                <div className="col-sm-9">{viewCategoryData?.data?.parent_category_name}</div>
              </h5>
            </div>
          </div>
          <div className="row mb-4 mb-sm-2">
            <div className="col-sm-3">
              <h5 className="f-w-500 d-flex justify-content-between">
                {t('page.category_name')}
                <span className="pull-right d-none d-sm-block">:</span>
                <div className="col-sm-9">{viewCategoryData?.data?.category_name}</div>
              </h5>
            </div>
          </div>
          <div className="row mb-4 mb-sm-2">
            <div className="col-sm-3">
              <h5 className="f-w-500 d-flex justify-content-between">
                {t('page.category_type')}
                <span className="pull-right d-none d-sm-block">:</span>
                <div className="col-sm-9">
                  {viewCategoryData?.data?.category_type == 1
                    ? 'parent-category'
                    : viewCategoryData?.data?.category_type == 2
                    ? 'sub-category'
                    : 'individual-category'}
                </div>
              </h5>
            </div>
          </div>
          <div className="row mb-4 mb-sm-2">
            <div className="col-sm-3">
              <h5 className="f-w-500 d-flex justify-content-between">
                {t('page.category_keyword')}
                <span className="pull-right d-none d-sm-block">:</span>
                <div className="col-sm-9">{viewCategoryData?.data?.keyword}</div>
              </h5>
            </div>
          </div>
          <div className="row mb-4 mb-sm-2">
            <div className="col-sm-3">
              <h5 className="f-w-500 d-flex justify-content-between">
                {t('page.category_sequence')}
                <span className="pull-right d-none d-sm-block">:</span>
                <div className="col-sm-9">{viewCategoryData?.data?.sequence}</div>
              </h5>
            </div>
          </div>
          <div className="row mb-4 mb-sm-2">
            <div className="col-sm-3">
              <h5 className="f-w-500 d-flex justify-content-between">
                {t('page.category_image')}
                <span className="pull-right d-none d-sm-block">:</span>
                <div className="col-sm-9">
                  <img
                    style={{ maxWidth: '50%' }}
                    src={viewCategoryData?.data?.category_image}
                    alt=""
                  />
                </div>
              </h5>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};
ViewCategory.propTypes = {
  t: PropTypes.func,
  isDirtyForm: PropTypes.any,
};
export default ViewCategory;
