import { useQuery, useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { settingData } from 'store/features/settingSlice';
import { SettingService } from 'api';
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useGetSettingData = (onSuccess, onError) => {
  return useQuery(
    'setting_data',
    () => {
      return SettingService.getAllSettingData();
    },
    {
      onSuccess,
      onError,
      // enabled: getSettingData.setting_get,
    }
  );
};
const useStoreSettingData = (onSuccess, onError = onDefaultError) => {
  return useMutation(SettingService.storeSettingData, {
    onSuccess,
    onError,
  });
};

const useGetSettingDataAlways = (onSuccess, onError) => {
  return useQuery(
    'setting_data_all',
    () => {
      return SettingService.getAllSettingData();
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useGetAllSettingData = (onSuccess, onError) => {
  return useQuery(
    'all_setting_data',
    () => {
      return SettingService.getAllSettingData();
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useGetHomeSettingData = (params, onSuccess, onError) => {
  return useQuery(
    ['home_setting_data', [params]],
    () => {
      return SettingService.getHomeSettingData({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};

const useGetMailNotificationData = (onSuccess, onError) => {
  return useQuery(
    'mail_notification_setting_data',
    () => {
      return SettingService.getMailNotificationData();
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useStoreMailNotificationData = (onSuccess, onError = onDefaultError) => {
  return useMutation(SettingService.storeMailNotificationData, {
    onSuccess,
    onError,
  });
};

const useStoreHomeSeoSettingData = (onSuccess, onError = onDefaultError) => {
  return useMutation(SettingService.storeHomeSeoSettingData, {
    onSuccess,
    onError,
  });
};
const useStoreHomeJobPostSettingData = (onSuccess, onError = onDefaultError) => {
  return useMutation(SettingService.storeHomeJobPostSettingData, {
    onSuccess,
    onError,
  });
};

const useStoreHomeBannerSettingData = (onSuccess, onError = onDefaultError) => {
  return useMutation(SettingService.storeHomeBannerSettingData, {
    onSuccess,
    onError,
  });
};
const useStoreHomeFooterSettingData = (onSuccess, onError = onDefaultError) => {
  return useMutation(SettingService.storeHomeFooterSettingData, {
    onSuccess,
    onError,
  });
};
const useStoreHomeDownloadSettingData = (onSuccess, onError = onDefaultError) => {
  return useMutation(SettingService.storeHomeDownloadSettingData, {
    onSuccess,
    onError,
  });
};
const useGetAboutSettingData = (params, onSuccess, onError) => {
  return useQuery(
    ['about_setting_data', [params]],
    () => {
      return SettingService.getAboutSettingData({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useStoreAboutBannerSettingData = (onSuccess, onError = onDefaultError) => {
  return useMutation(SettingService.storeAboutSettingData, {
    onSuccess,
    onError,
  });
};
const useStoreAboutSeoSettingData = (onSuccess, onError = onDefaultError) => {
  return useMutation(SettingService.storeAboutSeoSettingData, {
    onSuccess,
    onError,
  });
};
const useStoreAboutWhoWeAreSettingData = (onSuccess, onError = onDefaultError) => {
  return useMutation(SettingService.storeAboutWhoWeAreSettingData, {
    onSuccess,
    onError,
  });
};
const useStoreAboutOurMissionSettingData = (onSuccess, onError = onDefaultError) => {
  return useMutation(SettingService.storeAboutOurMissionSettingData, {
    onSuccess,
    onError,
  });
};
const useStoreAboutOurVisionSettingData = (onSuccess, onError = onDefaultError) => {
  return useMutation(SettingService.storeAboutOurVisionSettingData, {
    onSuccess,
    onError,
  });
};
const useStoreAboutOurTeamSettingData = (onSuccess, onError = onDefaultError) => {
  return useMutation(SettingService.storeAboutOurTeamSettingData, {
    onSuccess,
    onError,
  });
};
const useGetContactSettingData = (params, onSuccess, onError) => {
  return useQuery(
    ['contact_setting_data', [params]],
    () => {
      return SettingService.getContactSettingData({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};

const useStoreContactSettingData = (onSuccess, onError = onDefaultError) => {
  return useMutation(SettingService.storeContactSettingData, {
    onSuccess,
    onError,
  });
};
const useGetFaqSettingData = (params, onSuccess, onError) => {
  return useQuery(
    ['faq_setting_data', [params]],
    () => {
      return SettingService.getFaqSettingData({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};

const useStoreFaqSettingData = (onSuccess, onError = onDefaultError) => {
  return useMutation(SettingService.storeFaqSettingData, {
    onSuccess,
    onError,
  });
};
export {
  useGetSettingData,
  useStoreSettingData,
  useGetSettingDataAlways,
  useGetAllSettingData,
  useGetHomeSettingData,
  useStoreHomeSeoSettingData,
  useStoreHomeJobPostSettingData,
  useStoreHomeBannerSettingData,
  useStoreHomeFooterSettingData,
  useStoreHomeDownloadSettingData,
  useGetAboutSettingData,
  useStoreAboutBannerSettingData,
  useStoreAboutSeoSettingData,
  useStoreAboutWhoWeAreSettingData,
  useStoreAboutOurMissionSettingData,
  useStoreAboutOurVisionSettingData,
  useStoreAboutOurTeamSettingData,
  useStoreContactSettingData,
  useGetContactSettingData,
  useGetFaqSettingData,
  useStoreFaqSettingData,
  useGetMailNotificationData,
  useStoreMailNotificationData,
};
